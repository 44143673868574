<template>
	<div class="-edit-main">
		<!-- 添加表单 -->
		<el-form class="-form -full" :model="form" :rules="rules" :ref="setting.formName" @submit.native="_formSubmit()" @submit.native.prevent>
			<el-form-item label="类别名称" prop="name">
				<div class="-w300">
					<el-input v-model="form.name" />
				</div>
			</el-form-item>
			<el-form-item label="顺序" prop="orderBy">
				<div class="-w300">
					<el-input v-model="form.orderBy" />
				</div>
			</el-form-item>
			<div class="-edit-footer">
				<el-button type="primary" native-type="submit" title="按下'Ctrl + Enter'键可快速提交">提 交</el-button>
				<el-button title="按下'Esc'可快速关闭" @click="closeDrawer()">取 消</el-button>
			</div>
		</el-form>
		
	</div>
	
</template>

<script>
import { reactive } from 'vue'
import _edit  from '@/assets/js/edit'

export default {
	props: ['editId'],
	emits: [ 'onClose' ],
	setup(props, context) {
		return {
			context,
			closeDrawer: ()=>{context.emit('onClose', 'edit')},
			view: reactive({}),
			form: reactive({}),
			options: reactive({}),
			setting: reactive({
				moduleApi: '/Ctx/PortalCode/Class/',
				moduleName: '电子券类型',
			}),
			rules: {
				name: [
					{ required: true, message: '请输入电子券类型', trigger: 'blur' },
				]
			},
		}
	},
	
	mounted: function(){
		this._load(this, _edit)
	},
	methods: {
		
	}
}
</script>
